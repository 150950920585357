body {
  margin: 0;
  padding: 0;
  background: #000000;
  font-family: "DejaWeb", sans-serif;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card .image img {
  width: 100%;
  transition: .5s;
}

.card:hover .image img {
  opacity: .5;
  transform: translateX(30%);
  /*100%*/
}

.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  /*100%*/
  height: 99%;
  background: #F69C00;
  transition: .5s;
  transform-origin: left;
  transform: perspective(1000px) rotateY(-90deg);
}

.card:hover .details {
  transform: perspective(1000px) rotateY(0deg);
}

.card .details .center {
  padding: 20px;
  text-align: center;
  background: #e3e8e8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.card .details .center h1 {
  margin: 0;
  padding: 0;
  color: #4F9ED3;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.card .details .center h1 span {
  font-size: 14px;
  color: #262626;
}

.card .details .center p {
  padding: 0;
  color: #262626;
}

.card .details .center ul {
  padding: 0;
  display: table;
}

.card .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}

.card .details .center ul li a {
  display: block;
  color: rgb(0, 0, 0);
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.card .details .center ul li a:hover {
  background: #F69C00;
}

.card .details .center .hover-info {
  margin: 10px 0;
  min-height: 20px;
  color: #262626;
  font-size: 0.9em;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}